.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.header {
  font-size: calc(10px + 2vmin);
}
.header .logo {
  width: 128px;
}

.github-link {
  margin-top: 3em;
}
.github-link a {
  color: #61dafb;
}

.icp-links {
  position: absolute;
  bottom: 10px;
}

.icp-links a {
  font-size: 12px;
  color: #4d4b4b;
}
